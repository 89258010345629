import React from 'react';
import { navigate } from 'gatsby';
import { DefaultType } from 'types/default';

import { ButtonType } from 'components/atoms/button';
import Link from 'components/atoms/link';

import {
  StyledButton,
  StyledColumn,
  StyledContent,
  StyledDescription,
  StyledService,
  StyledServiceDescription,
  StyledServices,
  StyledServiceTitle,
  StyledSubHeading,
  StyledTitle,
  StyledWrapper,
} from './industries-services.styles';

export type IndustriesServiceType = {
  heading: string;
  text: string;
  linkButton: ButtonType;
};

export type IndustriesServicesType = {
  servicesList: IndustriesServiceType[];
  subHeading?: string;
  noArrowButton?: boolean;
  heading: string;
  text: string;
  bgColor?: string;
} & DefaultType;

type ServiceTitleWithLinkProps = {
  isLink: boolean;
  link: string;
  heading: string;
};

const extractLinkInfo = (linkButton: ButtonType): { link: string; isLink: boolean } => {
  const link = (linkButton?.link?.internal as { uri: string } | undefined)?.uri || '';
  return { link, isLink: Boolean(link) };
};

const ServiceTitleWithLink: React.FC<ServiceTitleWithLinkProps> = ({ isLink, link, heading }) =>
  isLink ? (
    <Link internal={{ uri: link }} ariaLabel={heading}>
      <StyledServiceTitle variant="title3" component="h3">
        {heading}
      </StyledServiceTitle>
    </Link>
  ) : (
    <StyledServiceTitle variant="title3" component="h3">
      {heading}
    </StyledServiceTitle>
  );

type ServiceItemProps = {
  service: IndustriesServiceType;
  idx: number;
  servicesLength: number;
  noArrowButton: boolean;
};

const ServiceItem: React.FC<ServiceItemProps> = ({
  service,
  idx,
  servicesLength,
  noArrowButton,
}) => {
  const { link, isLink } = extractLinkInfo(service.linkButton);

  return (
    <StyledService
      key={`${service.heading}-${idx}`}
      idx={idx}
      servicesLength={servicesLength}
      data-link={link}
      onClick={() => link && navigate(link)}
    >
      <ServiceTitleWithLink isLink={isLink} link={link} heading={service.heading} />
      <StyledServiceDescription variant="body1">{service.text}</StyledServiceDescription>
      {!noArrowButton && (
        <StyledButton
          {...service.linkButton}
          icon={{ name: 'arrow-right' }}
          width="max-content"
          variant="reversed-underline"
          className="card-with-icon__button"
        />
      )}
    </StyledService>
  );
};

const IndustriesServices: React.FC<IndustriesServicesType> = ({
  margin,
  padding,
  noArrowButton = false,
  heading,
  subHeading,
  text,
  servicesList,
  bgColor,
}) => (
  <StyledWrapper margin={margin} padding={padding} bgColor={bgColor}>
    <StyledContent>
      {subHeading && (
        <StyledSubHeading variant="title6" color="gray40">
          {subHeading}
        </StyledSubHeading>
      )}
      <StyledColumn>
        <StyledTitle html={heading} variant="title2" />
        <StyledDescription variant="body1" html={text} />
        <StyledServices>
          {servicesList.map((service, idx) => (
            <ServiceItem
              key={`${service.heading}-${idx}`}
              service={service}
              idx={idx}
              servicesLength={servicesList.length}
              noArrowButton={noArrowButton}
            />
          ))}
        </StyledServices>
      </StyledColumn>
    </StyledContent>
  </StyledWrapper>
);

export default IndustriesServices;
