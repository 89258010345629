import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { theme } from 'theme';
import { PageContextType } from 'types';

import Layout from 'components/templates/layout';
import HeadTitleDescriptionImage, {
  BannerPrimaryType,
} from 'components/organisms/head-title-description-image';
import IndustriesServices, {
  IndustriesServicesType,
} from 'components/organisms/industries-services';
import ManagerSection, { ManagerSectionType } from 'components/organisms/manager-section';
import OurMissionSection, {
  TextBannerWithImageType,
} from 'components/organisms/our-mission-section';
import TextBannerCardSection, {
  TextBannerCardType,
} from 'components/organisms/text-banner-card-section';
import BannerCard from 'components/molecules/banner-card';
import LetsTalkBanner, { LetsTalkBannerType } from 'components/molecules/lets-talk-banner';
import Breadcrumb from 'components/atoms/breadcrumb';

type DataProps = {
  commonComponents: {
    commonComponentsData: {
      letsTalkBanner: LetsTalkBannerType;
      otherServicesBlock: TextBannerCardType;
    };
  };
  offersHRConsultingPage: {
    template: {
      offersHRConsultingData: {
        bannerQuaternary: BannerPrimaryType;
        supportOffer: TextBannerCardType;
        hrConsultingSection: TextBannerWithImageType;
        advantagesSection: TextBannerCardType;
        hrConsultingServices: IndustriesServicesType;
        hrConsultingTeam: ManagerSectionType;
      };
    };
  };
};

export const OffersItContractingPage = ({
  pageContext,
  data: {
    offersHRConsultingPage: {
      template: {
        offersHRConsultingData: {
          bannerQuaternary: { heading, image, text },
          supportOffer,
          hrConsultingSection,
          advantagesSection,
          hrConsultingServices,
          hrConsultingTeam,
        },
      },
    },
    commonComponents: {
      commonComponentsData: { letsTalkBanner, otherServicesBlock },
    },
  },
}: PageProps<DataProps, PageContextType>) => (
  <Layout {...{ pageContext }}>
    <HeadTitleDescriptionImage
      titleTop={heading}
      subtitleTop={text}
      showDots
      positionTitle="left"
      image={image}
    >
      <Breadcrumb {...{ pageContext }} />
    </HeadTitleDescriptionImage>
    <TextBannerCardSection
      margin="0 auto"
      mobilePadding={`${theme.gap.mdl} ${theme.gap.sm} 0`}
      maxWidth={theme.wrapperXs}
      {...supportOffer}
      textColumn
    />
    <OurMissionSection
      firstSection={hrConsultingSection}
      margin="112px 0 0 0"
      mobileMargin={`${theme.gap.mdl} 0 0 0 `}
    />
    <TextBannerCardSection
      variantHeading="title8"
      {...advantagesSection}
      mobilePadding={`${theme.gap.mdl} ${theme.gap.sm} 20px`}
    >
      {advantagesSection.cardList.map((item) => (
        <BannerCard {...item} key={item.text} />
      ))}
    </TextBannerCardSection>
    <IndustriesServices {...hrConsultingServices} />
    <ManagerSection {...hrConsultingTeam} margin={`0 0 ${theme.gap.xl} 0`} mobileMargin="0px" />
    <LetsTalkBanner {...letsTalkBanner} margin={`0 auto ${theme.gap.xl}`} />
    <TextBannerCardSection {...otherServicesBlock} variantHeading="title7" textColumn>
      {otherServicesBlock && otherServicesBlock.cardList
        ? otherServicesBlock.cardList.map((item) => (
            <BannerCard maxWidth="340px" {...item} key={item.text} />
          ))
        : null}
    </TextBannerCardSection>
  </Layout>
);

export const query = graphql`
  query OffersHRConsultingPage($id: String, $commonComponentsSlug: String) {
    offersHRConsultingPage: wpPage(id: { eq: $id }) {
      template {
        templateName
        ... on WpTemplate_OffersHRConsulting {
          offersHRConsultingData {
            bannerQuaternary {
              heading
              text
              image {
                ...WpImageFragment
              }
            }
            supportOffer {
              heading
              text
            }
            advantagesSection {
              heading
              subTitle
              text
              cardList: advantagesList {
                text
                heading
                icon {
                  name
                }
              }
            }
            hrConsultingSection {
              heading
              subTitle
              text
              image {
                ...WpImageFragment
              }
              linkButton {
                icon {
                  name
                }
                label
                link {
                  ariaLabel
                  internal: wpInternal {
                    ... on WpPage {
                      uri
                    }
                  }
                  external {
                    target
                    url
                  }
                }
              }
            }
            hrConsultingServices {
              heading
              subHeading
              text
              servicesList {
                heading
                text
                linkButton {
                  icon {
                    name
                  }
                  label
                  link {
                    ariaLabel
                    internal: wpInternal {
                      ... on WpPage {
                        uri
                      }
                    }
                    external {
                      target
                      url
                    }
                  }
                }
              }
            }
            hrConsultingTeam {
              backgroundImage {
                ...WpImageFragment
              }
              businessCards {
                businessCard {
                  text
                  fullName
                  position
                  positionTitle
                  text
                  contactList {
                    type
                    link {
                      ariaLabel
                      wpInternal {
                        ... on WpPage {
                          id
                          uri
                        }
                      }
                      external {
                        target
                        url
                      }
                    }
                  }
                  image {
                    ...WpImageFragment
                  }
                }
              }
            }
          }
        }
      }
    }
    commonComponents: wpPage(slug: { eq: $commonComponentsSlug }) {
      commonComponentsData {
        letsTalkBanner {
          heading
          description
          linkButton {
            label
            icon {
              name
            }
            link {
              ariaLabel
              internal: wpInternal {
                ... on WpPage {
                  uri
                }
              }
            }
          }
        }
        otherServicesBlock {
          subTitle
          heading
          text
          cardList: servicesList {
            heading
            text
            icon {
              name
            }
            linkButton {
              label
              link {
                ariaLabel
                internal: wpInternal {
                  ... on WpPage {
                    id
                    uri
                  }
                }
                external {
                  target
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default OffersItContractingPage;
