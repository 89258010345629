import React from 'react';
import { theme } from 'theme';
import { DefaultType } from 'types/default';

import { ButtonType } from 'components/atoms/button';
import WpImage, { WpImageType } from 'components/atoms/wp-image';

import { useScreenService } from 'hooks/use-screen-service';

import {
  StyledBulletList,
  StyledBulletText,
  StyledButton,
  StyledContent,
  StyledDotsSeven,
  StyledDotsTwo,
  StyledPlaceholder,
  StyledText,
  StyledTextWrapper,
  StyledWpImageWrapper,
  StyledWrapper,
} from './our-mission-section.styles';

export type TextBannerWithImageType = {
  image: WpImageType;
  subTitle?: string;
  bulletsList?: string[];
  linkButton?: ButtonType;
} & Record<'heading' | 'text', string>;

export type OurMissionSectionType = {
  firstSection?: TextBannerWithImageType;
  secondSection?: TextBannerWithImageType;
  clearBg?: boolean;
  isSmallTitle?: boolean;
  largePadding?: boolean;
} & DefaultType;

const OurMissionSection = ({
  firstSection,
  secondSection,
  margin,
  mobileMargin,
  padding,
  clearBg,
  largePadding,
  mobilePadding,
  isSmallTitle,
}: OurMissionSectionType) => {
  const { isMobile } = useScreenService();
  const sectionToMap = [firstSection, secondSection];
  if (!sectionToMap.length) return null;

  const DotsGridTwo = (
    <StyledDotsTwo
      width={isMobile ? 0 : 2}
      height={isMobile ? 0 : 7}
      color={isMobile ? 'complementary3' : 'complementary3'}
    />
  );
  const DotsGridSeven = (
    <StyledDotsSeven
      width={isMobile ? 0 : 7}
      height={isMobile ? 0 : 7}
      color={isMobile ? 'complementary2' : 'complementary2'}
    />
  );

  const content = (data, isRight) => (
    <StyledContent isRight={isRight} clearBg={clearBg}>
      {!isRight ? <StyledPlaceholder /> : null}
      {!isRight ? DotsGridTwo : DotsGridSeven}
      <StyledWpImageWrapper isRight={isRight}>
        {data?.image ? <WpImage {...data?.image} /> : null}
      </StyledWpImageWrapper>
      <StyledTextWrapper largePadding={largePadding} isRight={isRight}>
        {data?.subTitle ? (
          <StyledText
            html={data?.subTitle}
            margin={isSmallTitle ? `0px 0 ${theme.gap.sm} 0` : `0px 0 ${theme.gap.md} 0`}
            color="gray40"
            variant="title6"
          />
        ) : null}

        <StyledText
          html={data?.heading}
          color="gray100"
          component="h2"
          variant={isSmallTitle ? 'title2' : 'title8'}
          margin={`0 0 ${theme.gap.md} 0 `}
          padding="0 0 0 0"
        />
        <StyledText
          html={data?.text}
          margin={`0 0 ${theme.gap.md} 0 `}
          color="gray100"
          variant="body1"
        />
        {data?.bulletsList ? (
          <StyledBulletList>
            {data?.bulletsList?.map((bullet) => (
              <StyledBulletText html={bullet.text} component="li" variant="body1" />
            ))}
          </StyledBulletList>
        ) : null}
        {data?.linkButton ? (
          <StyledButton {...data?.linkButton} size="md" variant="primary" />
        ) : null}
      </StyledTextWrapper>
    </StyledContent>
  );

  return (
    <StyledWrapper
      margin={margin}
      mobileMargin={mobileMargin}
      mobilePadding={mobilePadding}
      padding={padding}
    >
      {secondSection
        ? sectionToMap?.map((data?: TextBannerWithImageType, i?) => {
            const isRight = i % 2 !== 0;
            return content(data, isRight);
          })
        : content(firstSection, false)}
    </StyledWrapper>
  );
};

export default OurMissionSection;
