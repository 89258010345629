import styled from 'styled-components';
import { breakpoint } from 'theme';
import { DefaultType } from 'types/default';

import Button from 'components/atoms/button';
import Typography from 'components/atoms/typography';

export const StyledWrapper = styled.div<DefaultType>`
  padding-inline: ${({ theme }) => theme.gap.sm};
  display: flex;
  justify-content: center;
  padding-block: 36px;
  background-color: ${({ theme, bgColor }) => bgColor || theme.colors.grayscaleGray0};

  ${breakpoint.md} {
    padding-block: ${({ theme }) => theme.gap.xl};
  }
`;

export const StyledContent = styled.div`
  display: flex;
  max-width: ${({ theme }) => theme.wrapper};
  width: 100%;
  flex-direction: column;

  ${breakpoint.md} {
    flex-direction: row;
    gap: 30px;
  }
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledTitle = styled(Typography)`
  strong {
    text-decoration-color: transparent;

    ${breakpoint.md} {
      text-decoration-color: ${({ theme }) => theme.colors.primary50};
    }
  }
`;

export const StyledSubHeading = styled(Typography)`
  min-width: 347px;
  padding-bottom: ${({ theme }) => theme.gap.xs};
`;

export const StyledDescription = styled(Typography)`
  padding-block: ${({ theme }) => `${theme.gap.sm} ${theme.gap.md}`};

  ${breakpoint.md} {
    padding-bottom: 0;
  }
`;

export const StyledServices = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.gap.smd};

  ${breakpoint.md} {
    grid-template-columns: repeat(2, 1fr);
    gap: ${({ theme }) => `${theme.gap.sm}  30px`};
  }
`;

export const StyledService = styled.div<{ idx: number; servicesLength: number }>`
  ${breakpoint.md} {
    ${({ idx, theme }) =>
      idx === 0 || idx === 1 ? '' : `border-top:1px solid ${theme.colors.gray40}`};
    ${({ idx, theme, servicesLength }) =>
      idx === 1 && servicesLength % 2 !== 0
        ? `border-bottom:1px solid ${theme.colors.gray40}`
        : ''};
    ${({ idx, servicesLength }) =>
      idx === 1 && servicesLength % 2 !== 0 ? `padding-bottom: 16px` : ''};
    ${({ idx, servicesLength }) =>
      idx === 1 && servicesLength % 2 !== 0 ? `margin-bottom: -16px` : ''};
    min-height: 187px;
    padding-top: ${({ theme }) => theme.gap.md};
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary50};

    .industries-services__button span {
      ${breakpoint.md} {
        display: block;
      }
    }
  }

  &:hover {
    .card-with-icon__button {
      span {
        display: block;
        animation: move-text-cart 0.2s linear forwards;
      }

      svg {
        animation: move-svg 0.2s linear forwards;
      }
    }

    .card-with-icon__button span {
      ${breakpoint.md} {
        display: block;
      }
    }
  }

  @keyframes move-svg {
    from {
      opacity: 0;
      transform: translateX(-437%);
    }
    to {
      opacity: 1;
      transform: translateX(0%);
    }
  }

  @keyframes move-text-cart {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }
    to {
      opacity: 1;
      transform: translateX(0%);
    }
  }
`;

export const StyledServiceTitle = styled(Typography)``;

export const StyledButton = styled(Button)`
  span {
    text-decoration: underline;

    ${breakpoint.md} {
      display: none;
      text-decoration: none;
    }
  }
`;

export const StyledServiceDescription = styled(Typography)`
  padding-block: ${({ theme }) => theme.gap.xs};

  ${breakpoint.md} {
    padding-block: ${({ theme }) => theme.gap.sm};
  }
`;
