import React from 'react';
import { navigate } from 'gatsby';
import { DefaultType } from 'types/default';

import { ButtonType } from 'components/atoms/button';
import Icon, { IconType } from 'components/atoms/icon';
import Typography from 'components/atoms/typography';

import Link from '../../atoms/link';

import { StyledButton, StyledCard, StyledCardHeading, StyledCardText } from './banner-card.styles';

export type BannerCardType = {
  text?: string;
  icon?: IconType;
  numberCard?: string;
  bigTitle?: boolean;
  borderColor?: string;
  shadow?: boolean;
  linkButton?: ButtonType;
} & Record<'heading', string> &
  DefaultType;

const BannerCardHeading = ({ isLink, link, heading, bigTitle }) => {
  const headingVariant = bigTitle ? 'title1' : 'title3';

  return isLink ? (
    <Link internal={{ uri: link }} ariaLabel={heading}>
      <StyledCardHeading variant={headingVariant} component="h3">
        {heading}
      </StyledCardHeading>
    </Link>
  ) : (
    <StyledCardHeading variant={headingVariant} component="h3">
      {heading}
    </StyledCardHeading>
  );
};
const BannerCard = ({
  icon,
  text,
  heading,
  numberCard,
  bigTitle,
  borderColor,
  margin,
  padding,
  mobilePadding,
  bgColor,
  shadow,
  maxWidth,
  linkButton,
}: BannerCardType) => {
  const isBorderLeft = numberCard !== undefined || borderColor !== undefined || bigTitle;
  const link = (linkButton?.link?.internal as { uri: string } | undefined)?.uri.toString();
  const isLink = !!link;
  return (
    <StyledCard
      maxWidth={maxWidth}
      isBorderLeft={isBorderLeft}
      borderColor={borderColor}
      margin={margin}
      padding={padding}
      mobilePadding={mobilePadding}
      bgColor={bgColor}
      shadow={shadow}
      key={text}
      data-link={link || ''}
      onClick={() => link && navigate(link)}
    >
      {icon && <Icon {...icon} />}
      {numberCard && <Typography html={`${numberCard}`} variant="title1" component="h1" />}
      <StyledCardText>
        <BannerCardHeading isLink={isLink} link={link} heading={heading} bigTitle={bigTitle} />
        {text && <Typography variant={heading ? 'body1' : 'title3'}>{text}</Typography>}
        {linkButton ? (
          <StyledButton
            {...linkButton}
            icon={{ name: 'arrow-right' }}
            width="max-content"
            variant="reversed-underline"
            className="card-with-icon__button"
          />
        ) : null}
      </StyledCardText>
    </StyledCard>
  );
};

export default BannerCard;
