import styled from 'styled-components';
import { breakpoint } from 'theme';
import { DefaultType } from 'types/default';

import Typography from 'components/atoms/typography';

import Button from '../../atoms/button';

const widthCard = '255px';

type StyledCardType = {
  isBorderLeft?: boolean;
  borderColor?: string;
  shadow?: boolean;
  width?: string;
} & DefaultType;

export const StyledCard = styled.div<StyledCardType>`
  display: flex;
  gap: ${({ theme, isBorderLeft }) => (isBorderLeft ? theme.gap.xs : theme.gap.smd)};
  margin-top: ${({ theme }) => theme.gap.sm};
  border-left: ${({ isBorderLeft, borderColor }) =>
    isBorderLeft ? `1px solid ${borderColor}` : 'none'};
  padding: ${({ theme, isBorderLeft }) => (isBorderLeft ? `0 0 0 ${theme.gap.md} ` : 0)};
  flex-direction: ${({ isBorderLeft }) => (isBorderLeft ? 'column ' : 'row')};
  padding: ${({ mobilePadding }) => mobilePadding};
  background-color: ${({ bgColor }) => bgColor};
  box-shadow: ${({ shadow }) => shadow && '0px 2px 4px rgba(32, 37, 50, 0.08)'};

  ${breakpoint.md} {
    width: ${({ maxWidth }) => maxWidth || widthCard};
    margin: ${({ margin }) => margin};
    padding: ${({ padding }) => padding};
    flex-direction: column;
    align-items: center;
    gap: 18px;
    align-items: flex-start;
  }

  &:hover {
    .card-with-icon__button {
      span {
        display: block;
        animation: move-text-cart 0.2s linear forwards;
      }

      svg {
        animation: move-svg 0.2s linear forwards;
      }
    }

    .card-with-icon__button span {
      ${breakpoint.md} {
        display: block;
      }
    }
  }
  @keyframes move-svg {
    from {
      opacity: 0;
      transform: translateX(-437%);
    }
    to {
      opacity: 1;
      transform: translateX(0%);
    }
  }

  @keyframes move-text-cart {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }
    to {
      opacity: 1;
      transform: translateX(0%);
    }
  }
`;

export const StyledCardText = styled.div<{ tiles?: boolean }>`
  width: 84%;

  ${breakpoint.sm} {
    width: 100%;
  }

  ${breakpoint.md} {
    margin-top: ${({ tiles }) => (tiles ? 0 : '10px')};
  }
`;

export const StyledCardHeading = styled(Typography)<{ tiles?: boolean }>`
  max-width: 260px;
  ${breakpoint.md} {
    max-width: 100%;
    margin-bottom: ${({ theme, tiles }) => (tiles ? 0 : theme.gap.sm)};
  }
`;
export const StyledButton = styled(Button)`
  margin-top: 16px;

  span {
    text-decoration: none;

    ${breakpoint.md} {
      display: none;
      text-decoration: none;
    }
  }

  ${breakpoint.md} {
    margin-top: 20px;
  }
`;

export const StyledTextBanner = styled(Typography)`
  &[data-variant='title3'] {
    margin-bottom: 0;
  }
`;
