import styled from 'styled-components';
import { breakpoint } from 'theme';
import { DefaultType } from 'types/default';

import DotsGrid from 'components/molecules/dots-grid';
import Button from 'components/atoms/button';
import Typography from 'components/atoms/typography';

const textBoxWidth = '440px';
const heightBox = '768px';

type StyledWrapperType = {
  isRight?: boolean;
} & DefaultType;

export const StyledWrapper = styled.div<StyledWrapperType>`
  margin: ${({ mobileMargin }) => mobileMargin || `0`};
  padding: ${({ mobilePadding }) => mobilePadding || `0`};
  position: relative;
  ${breakpoint.md} {
    margin: ${({ margin }) => margin || `0`};
    padding: ${({ padding }) => padding};
  }
`;

export const StyledContent = styled.div<{
  isRight?: boolean;
  clearBg?: boolean;
}>`
  position: relative;
  max-width: ${({ theme }) => theme.wrapperXxl};
  margin: 0 auto;
  background: ${({ theme, isRight }) =>
    isRight ? `${theme.colors.white}` : `${theme.colors.grayscaleGray0}`};

  background: ${({ clearBg, theme }) => clearBg && `${theme.colors.white}`};

  ${breakpoint.lg} {
    display: flex;
    flex-direction: ${({ isRight }) => (isRight ? 'row-reverse' : `row`)};
    background: ${({ theme, isRight }) =>
      isRight
        ? `${theme.colors.white}`
        : `linear-gradient(90deg, ${theme.colors.white} 23.5%, ${theme.colors.grayscaleGray0} 23.5%)`};
    background: ${({ clearBg, theme }) => clearBg && `${theme.colors.white}`};
    margin-bottom: 72px;
    min-height: ${({ isRight }) => (isRight ? `unset` : `754px`)};
  }
`;

export const StyledWpImageWrapper = styled.div<{
  isRight?: boolean;
}>`
  left: 0;
  height: 216px;

  ${breakpoint.lg} {
    width: 49vw;
    max-width: 688px;
    position: ${({ isRight }) => (isRight ? 'relative' : 'absolute')};
    top: ${({ theme, isRight }) => (isRight ? '0' : `-${theme.gap.xl}`)};
    height: ${({ isRight }) => (isRight ? 'auto' : heightBox)};
  }
`;

export const StyledPlaceholder = styled.div`
  display: none;

  ${breakpoint.lg} {
    display: block;
    width: 688px;
  }
`;

export const StyledTextWrapper = styled.div<{
  isRight?: boolean;
  largePadding?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ isRight }) => (isRight ? 'center' : 'normal')};
  padding: ${({ theme }) => `${theme.gap.mdl} ${theme.gap.sm}`};
  max-width: ${({ theme }) => theme.wrapperXs};

  [data-variant='body1'] {
    margin-bottom: 0;
  }

  ul {
    margin-top: ${({ theme }) => theme.gap.md};

    li {
      margin-top: ${({ theme }) => theme.gap.xs};
      font-size: 14px;

      &::before {
        margin-right: ${({ theme }) => theme.gap.xxs};
        content: '•';
        color: ${({ theme }) => theme.colors.primary50};
      }
    }
  }

  ${breakpoint.lg} {
    margin: ${({ theme, isRight }) =>
      isRight ? `0 ${theme.gap.xl} 0 0` : `${theme.gap.xl} 0 0 ${theme.gap.xl}`};
    align-items: center;
    padding-block: ${({ theme, largePadding }) =>
      largePadding ? '24px 96px' : `0 ${theme.gap.md}`};
    padding-inline: 56px;

    ul {
      li {
        font-size: ${({ theme }) => theme.fontSize};
      }
    }
  }
`;

export const StyledText = styled(Typography)<{
  margin?: string;
  padding?: string;
}>`
  margin-bottom: ${({ theme }) => theme.gap.sm};
  max-width: ${({ theme }) => theme.wrapperXs};

  ${breakpoint.lg} {
    margin: ${({ margin }) => margin};
    padding: ${({ padding }) => padding};
    width: ${textBoxWidth};
  }
`;

export const StyledBulletList = styled.div`
  margin-top: ${({ theme }) => theme.gap.xs};

  ${breakpoint.lg} {
    margin-top: 0;
  }
`;

export const StyledBulletText = styled(Typography)`
  margin-top: ${({ theme }) => theme.gap.xs};
  max-width: ${({ theme }) => theme.wrapperXs};

  ::marker {
    color: ${({ theme }) => theme.colors.primary50};
    content: '▪   ';
  }

  ${breakpoint.lg} {
    width: ${textBoxWidth};
  }

  &:before {
    content: unset;
    position: absolute;
    top: 8px;
    left: -12px;
    height: 4px;
    width: 4px;
    background-color: ${({ theme }) => theme.colors.primary50};
    color: ${({ theme }) => theme.colors.primary50};

    ${breakpoint.md} {
      top: 10px;
    }
  }
`;

export const StyledButton = styled(Button)`
  align-self: flex-start;
  margin-top: ${({ theme }) => theme.gap.sm};

  ${breakpoint.md} {
    margin-top: ${({ theme }) => theme.gap.md};
  }
`;

export const StyledDotsTwo = styled(DotsGrid)`
  z-index: 10;
  display: none;

  ${breakpoint.lg} {
    display: block;
    bottom: 0%;
    left: 28px;
  }
`;

export const StyledDotsSeven = styled(DotsGrid)`
  z-index: 10;

  display: none;
  ${breakpoint.lg} {
    display: block;
    right: 32px;
    bottom: 32px;
  }
`;
